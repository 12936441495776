
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { CANDIDATE_STATUS } from "../../../constants/candidateStatus";

const NotInterestedModal = ({ open, setOpen, handleUpdateStatus, candidate }) => {
    const {
        candidate_id,
        firstname,
        lastname,
    } = candidate;

    const [comments, setComments] = useState(undefined);
    const handleCancel = () => {
        setOpen(false);
        setComments(undefined);
    };
    const handleAction = () => {
        handleUpdateStatus(candidate_id, CANDIDATE_STATUS.NOT_INTERESTED, comments);
        setOpen(false);
        setComments(undefined)
    }
    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle color="primary">Not Interested</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add comments to specify the reason why{" "}
                        <span style={{ color: "#1A76D2" }}>
                            {firstname} {lastname}
                        </span>
                        {" "}is not interested
                    </DialogContentText>
                    <TextField
                        onChange={(e) => setComments(e.target.value)}
                        autoFocus
                        margin="dense"
                        id="not interested"
                        label="Comments"
                        fullWidth
                        variant="standard"
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                        onClick={handleAction}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default NotInterestedModal