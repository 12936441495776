import TestForm from "../../../components/TestForm"
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const SendTestModal = ({ open, setOpen, candidate }) => {
    const {
        firstname,
        lastname,
        candidate_id
    } = candidate;


    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle color="primary">Send Test</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Send test email to{" "}
                        <span style={{ color: "#1A76D2" }}>
                            {firstname} {lastname}
                        </span>
                        , please pick the test and the end date below.{" "}
                    </DialogContentText>
                    <TestForm id={candidate_id} candidate={candidate} handleCloseModal={() => setOpen(false)} />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SendTestModal