import React, {useEffect} from 'react';
import actions from '../../redux/kpi/actions';
import BasicPie from './components/PieChart';
import BasicBarChart from './components/BarChart';
import DashboardHeader from '../../components/DashboardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import GlobalStats from './components/GlobalStats';
import { useDispatch, useSelector } from 'react-redux';


function KPI() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const {
    statsByJob : { data: statsByJobData, loading: statsByJobLoading, error: statsByJobError},
    statsByStatus : { data: statsByStatusData, loading: statsByStatusLoading, error: statsByStatusError},
    statsByLevel: { data: statsByLevelData, loading: statsByLevelLoading, error: statsByLevelError},
    filters: {jobs, level},
  } = useSelector((state) => state.kpi);

useEffect(() => {
    dispatch({
    type: actions.GET_GLOBAL_STATS,
});
  dispatch({
    type: actions.GET_STATS_BY_JOB,
});
dispatch({
    type: actions.GET_STATS_BY_STATUS,
});
dispatch({
    type: actions.GET_STATS_BY_LEVEL,
});
dispatch({
  type: actions.GET_FIELDS,
})
}, [dispatch]);

  return (
    <div className='dashboard-content'>
      <DashboardHeader />
      {authState.loading ? (
        <Box
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className='dashboard-content-container'>
          <div className='dashboard-content-header'>
            <h2>K.P.Is</h2>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
          <GlobalStats/>
            <BasicPie
             title='Candidates by jobs' 
              hasFilter
              filterLabel='Experience'
              filterData={level}
              dispatchType={actions.GET_STATS_BY_JOB}
             data={statsByJobData}
             loading={statsByJobLoading}
             error={statsByJobError}
             />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <BasicBarChart
                title='Candidates by experience'
                hasFilter
                filterLabel='Job title'
                filterData={jobs}
                dispatchType={actions.GET_STATS_BY_LEVEL}
                data={statsByLevelData}
                loading={statsByLevelLoading}
                error={statsByLevelError}
              />
              <BasicBarChart
                title='Candidates by status'
                hasFilter
                filterLabel='Job title'
                filterData={jobs}
                dispatchType={actions.GET_STATS_BY_STATUS}
                data={statsByStatusData}
                loading={statsByStatusLoading}
                error={statsByStatusError}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default KPI;
