import axios from "axios";
import config from "../config";

const url = config.app.apiUrl;
const token = localStorage.getItem("token");

const create = (baseURL = url) => {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": token ? token : "" ,
  };
  const api = axios.create({
    baseURL,
    headers,
  });

  const signin = (data) => api.post("/auth/login", data);

  const getAuthUser = () => api.get(`/user`);

  return {
    signin,
    getAuthUser,
  };
};

const apiAuth = {
  create,
};

export default apiAuth;
