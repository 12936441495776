import React, { useEffect, useState} from "react";
import DashboardHeader from "../../components/DashboardHeader";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@material-ui/core";
import FilterBar from "../../components/FilterBar";
import { Link, useLocation } from "react-router-dom";
import "../styles.css";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/candidates/actions";
import detailActions from "../../redux/candidateDetail/actions";
import Avatar from "@mui/material/Avatar";
import { CANDIDATE_STATUS_TEXT, getStatusKeyByValue } from "../../constants/candidateStatus";
import {useSearchParams} from "react-router-dom";
import { CANDIDATE_STATUS } from "../../constants/candidateStatus";
import AddCommentModal from "./components/AddCommentModal";
import ActionButton from "../CandidateDetails/components/ActionButton";
import SkillsTags from "./components/SkillsTags";

function Candidates() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const dispatch = useDispatch();
  const location = useLocation();
  const isTestPage = location.pathname==="/tests"
  const isTechPage = location.pathname==="/tech"
  const isHRPage = location.pathname==="/hr"
  const [open, setOpen] = useState(false);
  const state = useSelector((state) => state.candidateDetail);
  const {
    candidates,
    loading,
    error,
    currentPage,
    totalPages,
    selectedFilters,
    query
  } = useSelector((state) => state.candidates);

  useEffect(() => {
    let paramsQuery = searchParams.get("query");
    let jobs = searchParams.get("jobs");
    let status = searchParams.get("status");
    let skills = searchParams.get("skills");
    let countries = searchParams.get("countries");
    let level = searchParams.get("level");

    if(paramsQuery || jobs || status || skills || countries || level) {
        dispatch({ type: actions.GET_CANDIDATES_CALL, payload:{
            current:page,
            job_title: jobs.length ? JSON.parse(jobs).toString() : null,
            status: status ? getStatusKeyByValue(status) 
            : isTestPage ? CANDIDATE_STATUS.TEST_STAGE
            : isTechPage ? CANDIDATE_STATUS.TECH_INTERVIEW_STAGE
            : isHRPage ? CANDIDATE_STATUS.HR_INTERVIEW_STAGE
            : null,
            skills: skills !== "[]" ? skills : null,
            country: countries !== "[]" ? countries : null,
            level: level !== "[]" ? level : null,
            query: paramsQuery,
        }
      });
      } else {
        dispatch({ type: actions.GET_CANDIDATES_CALL, payload:{
          current: page, 
          status:  isTestPage ? CANDIDATE_STATUS.TEST_STAGE
          : isTechPage ? CANDIDATE_STATUS.TECH_INTERVIEW_STAGE
          : isHRPage ? CANDIDATE_STATUS.HR_INTERVIEW_STAGE
          :null,
          setSearchParams: () => {
            setSearchParams({page});
          }
        }});
      }

    return () => {
      dispatch({
        type: actions.RESET_STATE,
      });
    };
  }, [isTestPage, isTechPage, isHRPage, dispatch, setSearchParams, page, searchParams]);

  
  //=========================
  // Handle link click:
  const handleLink = (candidate) => {
    // sessionStorage.setItem("candidate-id", candidate.candidate_id);
    dispatch({
      type: detailActions.SET_STATE,
      payload: {
        ...state,
        candidate,
      },
    });
  };

  
 
  //Handle page change :
  const handlePageChange = (event, value) => {
    if (
      selectedFilters.jobs?.length ||
      selectedFilters.skills?.length ||
      selectedFilters.countries?.length ||
      selectedFilters.level?.length ||
      typeof selectedFilters.status === "number" ||
      query.trim().length
    ) {
      dispatch({
        type: actions.GET_CANDIDATES_CALL,
        payload: {
          job_title: selectedFilters.jobs?.length
        ? selectedFilters.jobs.toString()
        : null,
      country: selectedFilters.countries?.length
        ? JSON.stringify(selectedFilters.countries)
        : null,
        level: selectedFilters.level?.length
        ? JSON.stringify(selectedFilters.level)
        : null,
      skills: selectedFilters.skills?.length
        ? JSON.stringify(selectedFilters.skills)
        : null,
      status: isTestPage ? CANDIDATE_STATUS.TEST_STAGE
      : isTechPage ? CANDIDATE_STATUS.TECH_INTERVIEW_STAGE
      : isHRPage ? CANDIDATE_STATUS.HR_INTERVIEW_STAGE
      :selectedFilters.status >= 0 ? selectedFilters.status
          : null,
          current: value,
          query:query.trim().length ? query.trim() : null,
          setSearchParams : () => {
            setSearchParams({
              page: value,
              query: query.trim(),
              jobs: JSON.stringify(selectedFilters.jobs) || [],
              skills: JSON.stringify(selectedFilters.skills)|| [],
              countries: JSON.stringify(selectedFilters.countries)|| [],
              level: JSON.stringify(selectedFilters.level)|| [],
              status: CANDIDATE_STATUS_TEXT[selectedFilters.status] || [],
            });
          }

        },
      });
    } else {
      dispatch({ type: actions.GET_CANDIDATES_CALL, payload:{
        current:value ,status: isTestPage ? CANDIDATE_STATUS.TEST_STAGE
        : isTechPage ? CANDIDATE_STATUS.TECH_INTERVIEW_STAGE
        : isHRPage ? CANDIDATE_STATUS.HR_INTERVIEW_STAGE:null, 
        setSearchParams: () => { 
        setSearchParams({page: value,});
      }} });
    }
  };
  const handleClick = (candidate) => { 
    setOpen(true);dispatch({
    type: detailActions.SET_STATE,
    payload: {
      ...state,
      candidate,
    },
  }); }
  
  return (
    <div className="dashboard-content">
      <DashboardHeader />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          {isTestPage ? (
            <h2>Test stage Candidates</h2>
          ) : isTechPage ? (
            <h2>Tech stage Candidates</h2>
          ) : isHRPage ? (
            <h2>HR Stage Candidates</h2>
          ) : (
            <h2>Candidates List</h2>
          )}
        </div>
        <FilterBar
          isTestPage={isTestPage}
          isTechPage={isTechPage}
          isHRPage={isHRPage}
        />
        <table>
          <thead>
            <th>CANDIDATE</th>
            <th style={{ textAlign: "center", width: '100%'}}>SKILLS</th>
            <th style={{ textAlign: "center",  minWidth: '15rem' }}>SENIORITY</th>
            <th style={{ textAlign: "center" }}>POSITION</th>
            <th style={{ textAlign: "center", minWidth: '12rem' }}>STATUS</th>
          </thead>

          {loading ? (
            <>
              <td></td>

              <div className="dashboard-content-footer">
                <span className="empty-table">
                  <CircularProgress />
                  <br />
                  Loading...
                </span>
              </div>
              <td></td>
            </>
          ) : candidates.length !== 0 ? (
            <tbody>
              {candidates.map((candidate, index) => (
                <tr key={index}>
                  <td style={{paddingRight: 16, paddingLeft: 0}}>
                    <div style={{display: 'flex', flexWrap: 'no-wrap'}}>
                      <Avatar
                        className="dashboard-content-avatar"
                        alt={candidate.firstname}
                        src={candidate.picture}
                      />

                        <Link
                          style={{ textDecoration: "none", color: candidate.visible ? "#4CAF50" : "#0096FF" }}
                          to={`/candidate-details/${candidate.candidate_id}`}
                          onClick={() => handleLink(candidate)}
                        >
                          {candidate.firstname} {candidate.lastname}
                        </Link>

                    </div>
                  </td>
                  <td style={{padding: 0, textAlign: 'center'}}>
                   <SkillsTags skills={candidate.skills} />
                  </td>
                  <td style={{textAlign: "center", padding: 10}}>
                   {candidate?.level ? 
                  candidate?.level :
                  "--"}
                  </td>
                  <td>
                {candidate?.job_title} 
                 </td>
                  <td className="state-tag" style={{padding:'16px 0'}}>
                   <div style={{display: 'flex', flexDirection: 'column', alignItems:'flex-start', gap: 2}}>
                    <ActionButton candidate={candidate} candidateId={candidate.id} candidateStatus={candidate.status}/>
                    {(isTechPage || isHRPage) && (
                    <Button
                    onClick={() => handleClick(candidate)}
                    variant="outlined"
                    size="small"
                  >
                    Add comment
                  </Button>
                  )}
                  </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>

        {loading ? (
          <div className="dashboard-content-footer">
            <span className="empty-table">. . .</span>
          </div>
        ) : error ? (
          <div className="dashboard-content-footer">
            <span className="empty-table" style={{ color: "red" }}>
              {error}
            </span>
          </div>
        ) : candidates.length === 0 && !loading ? (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <Pagination
              count={totalPages}
              defaultPage={1}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              shape="rounded"
              color="primary"
              siblingCount={2}
              boundaryCount={2}
            />
          </div>
        )}
      </div>
      <AddCommentModal
        open={open}
        setOpen={setOpen}
        isTechPage={isTechPage}
        isHRPage={isHRPage}
      />
    </div>
  );
}

export default Candidates;
