import React from 'react';
import { useEffect } from 'react';
import DashboardHeader from '../../components/DashboardHeader';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/candidateDetail/actions';
import TestsAccordion from '../../components/TestsAccordion';
import HistoryAccordion from '../../components/HistoryAccordion';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { CANDIDATE_STATUS_TEXT } from '../../constants/candidateStatus';
import DeleteButton from './components/DeleteButton';
// import ActionButton from "./components/ActionButton";
import DetailsForm from './components/DetailsForm';
import { useParams, useNavigate } from 'react-router-dom';

function Details() {
  const params = useParams();
  const navigate = useNavigate();
  const { candidate_id, picture, firstname, status, visible, history } =
    useSelector((state) => state.candidateDetail.candidate);

  const { pageLoading, candidateTests } = useSelector(
    (state) => state.candidateDetail
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const { candidateId } = params;
    // if (!localStorage.getItem("token")) {
    //   window.location.href = "/login";
    if (candidate_id) {
      dispatch({
        type: actions.GET_CANDIDATE_DETAILS_CALL,
        payload: { candidate_id },
      });
      dispatch({
        type: actions.GET_TESTS_CALL,
      });
      dispatch({
        type: actions.GET_CANDIDATE_TESTS_CALL,
        payload: { candidate_id },
      });
    } else if (candidateId) {
      // let candidateId = sessionStorage.getItem("candidate-id");
      dispatch({
        type: actions.GET_CANDIDATE_DETAILS_CALL,
        payload: { candidate_id: candidateId },
      });
      dispatch({
        type: actions.GET_TESTS_CALL,
      });
      dispatch({
        type: actions.GET_CANDIDATE_TESTS_CALL,
        payload: { candidate_id: candidateId },
      });
    } else {
      // window.location.href = "/";
      navigate('/');
    }
  }, [candidate_id, dispatch, navigate, params]);

  // Update Candidate Visibility on the FD Website :
  const handleUpdateVisible = () => {
    dispatch({
      type: actions.UPDATE_CANDIDATE_VISIBLE_CALL,
      payload: {
        visible: !visible,
        candidate_id,
      },
    });
  };

  return (
    <div className='dashboard-content'>
      <DashboardHeader />
      <div className='dashboard-content-container'>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Divider
              role='presentation'
              textAlign='left'
              sx={{ width: '25rem' }}
            >
              <h2>Candidate details</h2>{' '}
            </Divider>
            <Chip
              sx={{ margin: '0 0.5rem' }}
              color={
                status === 5 ? 'success' : status === 7 ? 'error' : 'primary'
              }
              variant='outlined'
              label={pageLoading ? 'Loading...' : CANDIDATE_STATUS_TEXT[status]}
            />
            <Divider width='16%' />
            <div
              style={{
                marginLeft: 'auto',
                minWidth: '20vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '2rem',
              }}
            >
              <Tooltip
                title={
                  status !== 5 ? (
                    <Typography sx={{ fontSize: '14px', color: 'white' }}>
                      The candidate must have the 'approved' status to be
                      visible on the website.
                    </Typography>
                  ) : (
                    ''
                  )
                }
                placement='top'
              >
                <div
                  style={{
                    border: '1px solid #1976D2',
                    borderRadius: '6px',
                    padding: '0 8px',
                  }}
                >
                  <span>Visibility</span>
                  <Switch
                    disabled={status !== 5}
                    checked={visible}
                    onChange={() => handleUpdateVisible()}
                    size='big'
                    sx={{ cursor: status !== 5 ? 'not-allowed' : 'pointer' }}
                  />
                </div>
              </Tooltip>

              <DeleteButton />
            </div>
          </div>
          {!pageLoading ? (
            <Avatar
              sx={{ width: 200, height: 200, fontSize: '75px' }}
              alt={firstname}
              src={picture}
            />
          ) : (
            <Skeleton variant='circular' width={200} height={200} />
          )}
          <div className='card-container'>
            <DetailsForm />
            {candidateTests?.length > 0 ? <TestsAccordion /> : null}
            {history?.length > 0 ? <HistoryAccordion /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
