import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import Box from '@mui/material/Box';
import FilterBar from './FilterBar';
import EmptyStateComponent from './ErrorComponent';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import SpinnerComponent from './SpinnerComponent';

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));
  
  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  

export default function BasicPie({title, hasFilter, filterLabel, dispatchType, filterData, data, loading, error}) {

     const colorPalette = [
     '#3498db', '#9b59b6', '#f39c12', '#2ecc71'
   ,'#FF5733', '#34495e','#1abc9c', '#95a5a6', '#d35400',
    '#27ae60', '#2980b9', '#c0392b', '#f1c40f', '#8e44ad',
    '#16a085', '#2c3e50', '#bdc3c7', '#e67e22', '#7f8c8d','#e74c3c',
    // Add more colors as needed
  ];


  return (
    <Box sx={{ width: 'auto', height: 300, borderRadius: 4, border: '1px solid lightgray', padding: 2}}>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
    <h2>{title}</h2>
  {hasFilter && <FilterBar title={filterLabel} dispatchType={dispatchType} data={filterData}/>}
    </div>
  
  {
    loading ? <SpinnerComponent/> : error ? <EmptyStateComponent error={error}/> : data?.orderedData?.length &&
  
    <PieChart
     colors={colorPalette}
     loading={true}
      series={[
        {
          data: data.orderedData,
          innerRadius: 34,
          cornerRadius: 5,
          paddingAngle: 5,
        },
      ]}
      height={200}
    >
   <PieCenterLabel>{data?.totalCandidates}</PieCenterLabel>
    </PieChart>
}
    </Box>
  );
}