const routesList = {
    candidates: "/",
    candidateDetails: "/candidate-details",
    candidateTests: "/tests",
    candidatesTech: "/tech",
    candidatesHR: "/hr",
    testCorrection: "/test-correction",
    login: "/login",
    profile: "/profile",
    createAgent: "/create-agent",
    manageAgents: "/manage-agents",
    updateAgent: "/update-agent",
    kpis : "/kpis",
};

export default routesList;