import { combineReducers } from "redux";
import CreateStore from "./createStore";
//import all root sagas:
import rootSaga from "./sagas";

//import all reducers:
import auth from "./auth/reducer";
import candidates from "./candidates/reducer";
import candidateDetail from "./candidateDetail/reducer";
import agents from "./agents/reducer";
import kpi from "./kpi/reducer";
import notification from "./notification/reducer";

//assemble the reducers:
const reducers = combineReducers({
  auth,
  candidates,
  candidateDetail,
  agents,
  kpi,
  notification,
});

const reducer = () => {
  const { store } = CreateStore(reducers, rootSaga);
  return store;
};

export default reducer;
