import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import actions from '../../redux/candidates/actions';

import SideBarItem from "./sidebar-item";

import "./styles.css";
import logo from "../../assets/images/logo_factory_digitale.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import { logout } from "../../utils/auth";

function SideBar({ menu, rootMenu }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isTestPage = location.pathname==="/tests"
  const isTechPage = location.pathname==="/tech"
  const isHRPage = location.pathname==="/hr"
  const { roles, loading } = useSelector((state) => state.auth);
  const [active, setActive] = useState(1);

  const [activeUser, setActiveUser] = useState(false);

  // const handleLogOut = () => {
  //   localStorage.removeItem("token");
  //   window.location.href = "/login";
  // };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setActiveUser(true);
    }
    menu.forEach((element) => {
      if (location.pathname === element.path) {
        setActive(element.id);
      }
    });
  }, [location.pathname, menu]);

  const __navigate = (id) => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        selectedFilters: {
    skills: [],
    jobs: [],
    countries: [],
    status: isTestPage ? 1 : isTechPage ? 2 : isHRPage ? 3 : "",
    level: [],
  },
        query: '',
      },
    });
    setActive(id);
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-container">
        <div className="sidebar-logo-container">
          <img src={logo} alt="logo" />
        </div>

        <div className="sidebar-container">
          <div className="sidebar-items">
            {menu.map((item, index) => (
              <div key={index} onClick={() => __navigate(item?.id)}>
                <SideBarItem active={item?.id === active} item={item} />
              </div>
            ))}
            {!loading &&
              roles.includes("root") &&
              rootMenu?.map((item, index) => (
                <div key={index} onClick={() => __navigate(item?.id)}>
                  <SideBarItem active={item?.id === active} item={item} />
                </div>
              ))}
          </div>
          {activeUser && (
            <div className="sidebar-footer" onClick={logout}>
              <span className="sidebar-item-label">Logout</span>
              <img
                src={LogoutIcon}
                alt="icon-logout"
                className="sidebar-item-icon"
              />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
