import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  all,
} from "redux-saga/effects";
import actions from "./actions";
import candidatesActions from '../candidates/actions'
import notificationActions from "../notification/actions";
import { CANDIDATE_STATUS } from "../../constants/candidateStatus";

export function* getCandidateDetailsCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const { candidate_id } = payload

  try {
    const response = yield call(api.getCandidateDetails, candidate_id);
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...state,
          candidate: { ...response.data.data },
          pageLoading: false,
        },
      });
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...state,
        error: error.message,
        pageLoading: false,
      },
    });
  }
}

export function* updateCandidateInfoCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(api.updateCandidate, state.candidate.candidate_id, payload);
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: payload.firstname
          ? {
            candidate: { ...state.candidate, firstname: payload.firstname },
            loading: false,
          }
          : payload.lastname
            ? {
              candidate: { ...state.candidate, lastname: payload.lastname },
              loading: false,
            }
            : payload.bioState ?
              {
                candidate: { ...state.candidate, bio: payload.bioState },
                loading: false,
              }
              :
              {
                candidate: { ...state.candidate, collaboration: payload.collaboration },
                loading: false,
              }
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Candidate updated successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...state,
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}

export function* updateCandidateStatusCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const { candidate_id , status, comments, handleSuccess } = payload;
  const candidatesState = yield select((state) => state.candidates);

  yield put({
    type: actions.SET_STATE,
    payload: {
      candidate: {
        candidate_id,
      },
      loading: true,
    },
  });
  try {
    const response = yield call(api.updateCandidate, candidate_id,
      { status, comments });
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...state,
          candidate: { ...state.candidate, status },
          loading: false,
        },
      });
      yield put({
        type: candidatesActions.SET_STATE,
        payload: {
          candidates: candidatesState?.candidates?.map(candidate => {
            if (candidate.candidate_id === candidate_id) {
              return {
                ...candidate,
                status,
              };
            }
            return candidate;
          }),
          loading: false,
          error: null,
        },
      });
      if (handleSuccess) handleSuccess()
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: status === CANDIDATE_STATUS.OFFER_SENT ? "Offer sent successfully!" : "Candidate status updated successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...state,
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}

export function* updateCandidateVisibleCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const { candidate_id, visible } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      error: null,
    },
  });

  try {
    const response = yield call(api.updateCandidate, candidate_id, { visible });
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...state,
          candidate: { ...state.candidate, visible },
          loading: false,
        },
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: visible ? "Candidate is now visible on FactoryDigitale !" : "Candidate is no longer visible on the website !",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...state,
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}

export function* deleteCandidateCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const { candidate_id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(api.deleteCandidate, candidate_id);
    if (response && response.status === 200) {
      sessionStorage.removeItem("candidate-id");
      window.location.href = "/";
      yield put({
        type: actions.SET_STATE,
        payload: {
          ...state,
          candidate: {},
          loading: false,
        },
      });
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...state,
        error: error.message,
        loading: false,
      },
    });
  }
}

export function* getTestsCall(api) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(api.getTests);
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          tests: response.data,
          loading: false,
        },
      });
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
  }
}

export function* getCandidateTestsCall(api, { payload }) {
  const { candidate_id, status } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(api.getCandidateTests, candidate_id, { status });
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          candidateTests: response.data.data,
          loading: false,
        },
      });
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
  }
}

export function* sendTestCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const candidatesState = yield select((state) => state.candidates);

  const { test_title, deadline, candidate_id, candidate } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      candidate,
      loading: true,
      error: null,
    },
  });

  try {
    const response = yield call(api.sendTest, candidate_id,
      { test_title, deadline });
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          candidate: { ...state.candidate, status: CANDIDATE_STATUS.TEST_STAGE },
          loading: false,
          error: null,
        },
      });
      yield put({
        type: candidatesActions.SET_STATE,
        payload: {
          candidates: candidatesState?.candidates?.map(candidate => {
            if (candidate.candidate_id === candidate_id) {
              return {
                ...candidate,
                status: CANDIDATE_STATUS.TEST_STAGE,
              };
            }
            return candidate;
          }),
          loading: false,
          error: null,
        },
      });
      
      // handleSuccess();
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Test sent successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error?.message || error,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}

export function* sendTechInterviewEmailCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const candidatesState = yield select((state) => state.candidates);
  yield put({
    type: actions.SET_STATE,
    payload: {
      candidate: payload,
      loading: true,
      error: null,
    },
  });

  const {candidate_id} = payload;
  try {
    const response = yield call(api.sendTechInterviewEmail, candidate_id);

    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          candidate: { ...state.candidate, status: CANDIDATE_STATUS.TECH_INTERVIEW_STAGE },
          loading: false,
        },
      });
      // payload.handleSuccess()
      yield put({
        type: candidatesActions.SET_STATE,
        payload: {
          candidates: candidatesState?.candidates?.map(candidate => {
            if (candidate.candidate_id === candidate_id) {
              return {
                ...candidate,
                status: CANDIDATE_STATUS.TECH_INTERVIEW_STAGE,
              };
            }
            return candidate;
          }),
          loading: false,
          error: null,
        },
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Tech interview email sent successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}
export function* sendHRInterviewEmailCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const candidatesState = yield select((state) => state.candidates);
  const {candidate_id} = payload;

  yield put({
    type: actions.SET_STATE,
    payload: {
      candidate: payload,
      loading: true,
      error: null,
    },
  });
  try {
    const response = yield call(api.sendHRInterviewEmail, candidate_id);

    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          candidate: { ...state.candidate, status: CANDIDATE_STATUS.HR_INTERVIEW_STAGE },
        },
      });
      // payload.handleSuccess()
      yield put({
        type: candidatesActions.SET_STATE,
        payload: {
          candidates: candidatesState?.candidates?.map(candidate => {
            if (candidate.candidate_id === candidate_id) {
              return {
                ...candidate,
                status: CANDIDATE_STATUS.HR_INTERVIEW_STAGE,
              };
            }
            return candidate;
          }),
          loading: false,
          error: null,
        },
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "HR interview email sent successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}

export function* sendAcceptanceEmailCall(api, { payload }) {
  const state = yield select((state) => state.candidateDetail);
  const candidatesState = yield select((state) => state.candidates);
  const {candidate_id} = payload;
  
  yield put({
    type: actions.SET_STATE,
    payload: {
      candidate: payload,
      loading: true,
      error: null,
    },
  });
  try {
    const response = yield call(api.sendAcceptanceEmail, candidate_id);

    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          candidate: { ...state.candidate, status: CANDIDATE_STATUS.APPROVED },
        },
      });
      // payload.handleSuccess()
      yield put({
        type: candidatesActions.SET_STATE,
        payload: {
          candidates: candidatesState?.candidates?.map(candidate => {
            if (candidate.candidate_id === candidate_id) {
              return {
                ...candidate,
                status: CANDIDATE_STATUS.APPROVED,
              };
            }
            return candidate;
          }),
          loading: false,
          error: null,
        },
      });
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Acceptance email sent successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}
export function* evaluateTestCall(api, { payload }) {
  const { test_id, score, submission_date, comment, sendEmail, urls, handleSuccess } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const response = yield call(api.evaluateTest, test_id, { score, submission_date, comment, sendEmail, urls });
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
      handleSuccess()
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Test evaluation saved successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}

export function* cancelTestCall(api, { payload }) {
  const { test_id, comment, handleSuccess } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  try {
    const response = yield call(api.cancelTest, test_id, { comment });
    if (response && response.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      });
      handleSuccess()
      yield put({
        type: notificationActions.SET_STATE,
        payload: {
          open: true,
          message: "Test cancelled successfully!",
          severity: "success"
        },
      })
    }
  } catch (error) {
    console.error("error: ", error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        error: error.message,
        loading: false,
      },
    });
    yield put({
      type: notificationActions.SET_STATE,
      payload: {
        open: true,
        message: error.message,
        severity: "error"
      },
    })
  }
}

export default function* rootSaga(api) {
  yield all([
    takeEvery(actions.GET_CANDIDATE_DETAILS_CALL, getCandidateDetailsCall, api),
    takeEvery(actions.UPDATE_CANDIDATE_INFO, updateCandidateInfoCall, api),
    takeEvery(
      actions.UPDATE_CANDIDATE_STATUS_CALL,
      updateCandidateStatusCall,
      api
    ),
    takeEvery(
      actions.UPDATE_CANDIDATE_VISIBLE_CALL,
      updateCandidateVisibleCall,
      api
    ),
    takeEvery(actions.DELETE_CANDIDATE_CALL, deleteCandidateCall, api),
    takeEvery(actions.GET_TESTS_CALL, getTestsCall, api),
    takeEvery(actions.SEND_TEST_CALL, sendTestCall, api),
    takeLatest(
      actions.SEND_TECH_INTERVIEW_EMAIL_CALL,
      sendTechInterviewEmailCall,
      api
    ),
    takeLatest(actions.SEND_HR_INTERVIEW_EMAIL_CALL, sendHRInterviewEmailCall, api),
    takeLatest(actions.SEND_ACCEPTANCE_EMAIL_CALL, sendAcceptanceEmailCall, api),
    takeLatest(actions.GET_CANDIDATE_TESTS_CALL, getCandidateTestsCall, api),
    takeLatest(actions.EVALUATE_TEST_CALL, evaluateTestCall, api),
    takeLatest(actions.CANCEL_TEST_CALL, cancelTestCall, api),
  ]);
}
