import * as React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import actions from "../../redux/candidates/actions";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDispatch, useSelector } from "react-redux";
import {
  CANDIDATE_STATUS,
  CANDIDATE_STATUS_TEXT,
  getStatusKeyByValue,
} from "../../constants/candidateStatus";
import { useSearchParams, useLocation } from "react-router-dom";

import "./styles.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({ isTestPage, isTechPage,isHRPage }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const [personName, setPersonName] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  let jobs = searchParams.get("jobs");
  let countries = searchParams.get("countries");
  let status = searchParams.get("status") || "";
  let skills = searchParams.get("skills");
  let level = searchParams.get("level");

  const [filters, setFilters] = React.useState({
    skills: JSON.parse(skills) || [],
    jobs: JSON.parse(jobs) || [],
    countries: JSON.parse(countries) || [],
    level: JSON.parse(level) || [],
    status: isTestPage
      ? 1
      : isTechPage
      ? 2
      : isHRPage
      ? 3
      : status
      ? getStatusKeyByValue(status)
      : "",
  });
  const filtersList = useSelector((state) => state.candidates.filters);
  const {
    selectedFilters,
    // query: selectedQuery,
    action: { loading },
  } = useSelector((state) => state.candidates);
  const [query, setQuery] = useState(searchParams.get("query") || "");
  
  React.useEffect(() => {
    dispatch({ type: actions.GET_FILTERS_CALL });
    dispatch({
      type: actions.SET_STATE,
      payload: {
        selectedFilters: { ...filters, },
        query: '',
      },
    });
    setFilters({...filters, status : isTestPage ? 1 : isTechPage ? 2 : isHRPage ? 3 : "",});
    setQuery('')
  }, [location?.pathname, dispatch, filters, isTestPage, isTechPage, isHRPage]);

  const handleChange = (event, filterType) => {
    const {
      target: { value },
    } = event;

    if (filterType === "skills") {
      setFilters(
        // On autofill we get a stringified value.
        typeof value === "string"
          ? { ...filters, skills: value.split(",") }
          : { ...filters, skills: value }
      );
      dispatch({
        type: actions.SET_STATE,
        payload: {
          selectedFilters:
            typeof value === "string"
              ? { ...filters, skills: value.split(",") }
              : { ...filters, skills: value },
        },
      });
    } else if (filterType === "jobs") {
      setFilters(
        typeof value === "string"
          ? { ...filters, jobs: value.split(",") }
          : { ...filters, jobs: value }
      );
      dispatch({
        type: actions.SET_STATE,
        payload: {
          selectedFilters:
            typeof value === "string"
              ? { ...filters, jobs: value.split(",") }
              : { ...filters, jobs: value },
        },
      });
    } else if (filterType === "countries") {
      setFilters(
        typeof value === "string"
          ? { ...filters, countries: value.split(",") }
          : { ...filters, countries: value }
      );
      dispatch({
        type: actions.SET_STATE,
        payload: {
          selectedFilters:
            typeof value === "string"
              ? { ...filters, countries: value.split(",") }
              : { ...filters, countries: value },
        },
      });
    } else if (filterType === "level") {
      setFilters(
        typeof value === "string"
          ? { ...filters, level: value.split(",") }
          : { ...filters, level: value }
      );
      dispatch({
        type: actions.SET_STATE,
        payload: {
          selectedFilters:
            typeof value === "string"
              ? { ...filters, level: value.split(",") }
              : { ...filters, level: value },
        },
      }); 
     } else if (filterType === "status") {
      setFilters({ ...filters, status: value });
      dispatch({
        type: actions.SET_STATE,
        payload: { selectedFilters: { ...filters, status: value } },
      });
    }
  };

  const clearFilters = () => {
    const initFilters = {
      skills: [],
      jobs: [],
      countries: [],
      level: [],
      status: isTestPage ? 1 : isTechPage ? 2 : isHRPage ? 3 : "",
    };
    setQuery("");
    setFilters(initFilters);
    setSearchParams({ page: 1 });

    if (isTestPage) {
      dispatch({
        type: actions.GET_CANDIDATES_CALL,
        payload: {
          ...initFilters,
          status: 1,
          query: null,
          setSearchParams: () => {
            setSearchParams({ page: 1 });
          },
        },
      });
      // sessionStorage.setItem("tests-page", 1);
    } else if (isTechPage) {
      dispatch({
        type: actions.GET_CANDIDATES_CALL,
        payload: {
          ...initFilters,
          status: 2,
          query: null,
          setSearchParams: () => {
            setSearchParams({ page: 1 });
          },
        },
      });
      // sessionStorage.setItem("tech-page", 1);
    } else if (isHRPage) {
      dispatch({
        type: actions.GET_CANDIDATES_CALL,
        payload: {
          ...initFilters,
          status: 3,
          query: null,
          setSearchParams: () => {
            setSearchParams({ page: 1 });
          },
        },
      });
      // sessionStorage.setItem("tech-page", 1);
    }else {
      dispatch({
        type: actions.GET_CANDIDATES_CALL,
        payload: {
          ...initFilters,
          query: null,
          setSearchParams: () => {
            setSearchParams({ page: 1 });
          },
        },
      });
      // sessionStorage.setItem("page", 1);
    }

    dispatch({
      type: actions.SET_STATE,
      payload: {
        selectedFilters: initFilters,
        query: "",
      },
    });
  };

  const buttonExportClick = () => {
    if (
      selectedFilters.jobs?.length ||
      selectedFilters.skills?.length ||
      selectedFilters.countries?.length ||
      selectedFilters.level?.length ||
      typeof selectedFilters.status === "number" ||
      query.trim().length
    ) {
      dispatch({
        type: actions.EXPORT_CANDIDATES_CALL,
        payload: {
          job_title: selectedFilters.jobs?.length
            ? selectedFilters.jobs.toString()
            : null,
          country: selectedFilters.countries?.length
            ? JSON.stringify(selectedFilters.countries)
            : null,
          skills: selectedFilters.skills?.length
            ? JSON.stringify(selectedFilters.skills)
            : null,
            level: selectedFilters.level?.length
            ? JSON.stringify(selectedFilters.level)
            : null,
          status: isTestPage
            ? CANDIDATE_STATUS.TEST_STAGE
            : isTechPage
            ? CANDIDATE_STATUS.TECH_INTERVIEW_STAGE
            : isHRPage
            ? CANDIDATE_STATUS.HR_INTERVIEW_STAGE
            : selectedFilters.status >= 0
            ? selectedFilters.status
            : null,

          query: query.trim().length ? query.trim() : null,
        },
      });
    } else {
      dispatch({
        type: actions.EXPORT_CANDIDATES_CALL,
        payload: {
          status: isTestPage
            ? CANDIDATE_STATUS.TEST_STAGE
            : isTechPage
            ? CANDIDATE_STATUS.TECH_INTERVIEW_STAGE
            : isHRPage
            ? CANDIDATE_STATUS.HR_INTERVIEW_STAGE
            : null,
        },
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: actions.GET_CANDIDATES_CALL,
      payload: {
        job_title: filters.jobs?.length ? filters.jobs.toString() : null,
        country: filters.countries?.length
          ? JSON.stringify(filters.countries)
          : null,
          level: filters.level?.length
          ? JSON.stringify(filters.level)
          : null,
        skills: filters.skills?.length ? JSON.stringify(filters.skills) : null,
        status: filters.status >= 0 ? filters.status : null,
        current: 1,
        query: query.trim().length ? query.trim() : null,
        setSearchParams: () => {
          setSearchParams({
            page: 1,
            query: query.trim(),
            jobs: JSON.stringify(filters.jobs) || "",
            skills: JSON.stringify(filters.skills) || "",
            countries: JSON.stringify(filters.countries) || "",
            level: JSON.stringify(filters.level) || "",
            status: CANDIDATE_STATUS_TEXT[filters.status] || "",
          });
        },
      },
    });
    dispatch({
      type: actions.SET_STATE,
      payload: { query: query.trim() },
    });
  };

  const getLevelLabel = (selectValue) => {
  const result = filtersList?.level?.find((item) => item.value === selectValue);
  return result ? result.label : null;
};

  return (
    <div>
      <form
        onSubmit={(e) => handleSubmit(e)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="dashboard-content-form">
          <input
            type="text"
            value={query}
            placeholder="Search.."
            className="dashboard-content-input"
            onChange={(e) => setQuery(e.target.value)}
          />

          <Button sx={{ width: "13vw" }} type="submit" variant="contained">
            SEARCH
          </Button>
        </div>
        <div>
          <FormControl
            sx={{ m: 1, minWidth: 120, size: "small", maxWidth: 310 }}
          >
            <InputLabel id="demo-multiple-chip-label">Skills</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={filters?.skills}
              onChange={(e) => handleChange(e, "skills")}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {filtersList.skills.length ? (
                filtersList.skills.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value="noData"
                  style={getStyles("noData", personName, theme)}
                  disabled
                >
                  No Data
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            sx={{ m: 1, minWidth: 120, size: "small", maxWidth: 310 }}
          >
            <InputLabel id="demo-multiple-chip-label">Jobs</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={filters?.jobs}
              onChange={(e) => handleChange(e, "jobs")}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {filtersList.jobs.length ? (
                filtersList.jobs.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value="noData"
                  style={getStyles("noData", personName, theme)}
                  disabled
                >
                  No Data
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            sx={{ m: 1, minWidth: 120, size: "small", maxWidth: 310 }}
          >
            <InputLabel id="demo-multiple-chip-label">Seniority</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={filters?.level}
              onChange={(e) => handleChange(e, "level")}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => {
                return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={getLevelLabel(value)} />
                  ))}
                </Box>
              )}}
              MenuProps={MenuProps}
            >
              {filtersList.level.length ? (
                filtersList.level.map((level) => (
                  <MenuItem
                    key={level.value}
                    value={level.value}
                    style={getStyles(level.label, personName, theme)}
                  >
                    {level.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value="noData"
                  style={getStyles("noData", personName, theme)}
                  disabled
                >
                  No Data
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            sx={{ m: 1, minWidth: 120, size: "small", maxWidth: 310 }}
          >
            <InputLabel id="demo-multiple-chip-label">Countries</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={filters?.countries}
              onChange={(e) => handleChange(e, "countries")}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {filtersList.countries.length ? (
                filtersList.countries.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  value="noData"
                  style={getStyles("noData", personName, theme)}
                  disabled
                >
                  No Data
                </MenuItem>
              )}
            </Select>
          </FormControl>
          {!isTechPage && !isTestPage && !isHRPage && (
            <FormControl
              sx={{ m: 1, minWidth: 120, size: "small", maxWidth: 310 }}
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filters?.status}
                label="Age"
                onChange={(e) => handleChange(e, "status")}
              >
                {filtersList.status ? (
                  filtersList.status.map((name, index) => (
                    <MenuItem
                      key={name}
                      value={index}
                      style={getStyles(name, personName, theme)}
                    >
                      {CANDIDATE_STATUS_TEXT[index]}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    value="noData"
                    style={getStyles("noData", personName, theme)}
                    disabled
                  >
                    No Data
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={clearFilters}
            style={{
              height: `${ITEM_HEIGHT}px`,
              margin: theme.spacing(2),
            }}
            size="small"
          >
            X{" "}
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{
              height: `${ITEM_HEIGHT}px`,
            }}
          >
            Filter
          </Button>{" "}
          <LoadingButton
          onClick={buttonExportClick}
           loading={loading}
           loadingPosition="start"
           startIcon={<FileDownloadOutlinedIcon />}
           variant="outlined"
           style={{height: `${ITEM_HEIGHT}px`}}>
           Export
          </LoadingButton>
{" "}
        </div>
      </form>
    </div>
  );
}


